<template>
	<div class="body-function">
		<div class="child-top">
			<div class="input-from">
				<el-cascader
					@change="getData"
					style="float: left"
					placeholder="请选择运动队"
					v-model="selectArr.deparmentId"
					:options="departmentOptions.options"
					collapse-tags
					:show-all-levels="false"
					:props="{ multiple: true, children: 'children', label: 'name', value: 'uuid', emitPath: false }"
				>
				</el-cascader>
				<el-select @change="selectTypeArr" @clear="clearSelect" clearable filterable placeholder="请选择项目" v-model="selectArr.type">
					<el-option :key="item.uuid" :label="item.name" :value="item.uuid" v-for="item in typeOptions.options"></el-option>
				</el-select>

				<el-select @change="getData" clearable placeholder="请选择性别" v-model="selectArr.sex">
					<el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in sexOptions.options"></el-option>
				</el-select>

				<el-select @change="getData" clearable placeholder="请选择离队状态" v-model="selectArr.isOut">
					<el-option label="在队" value="0"></el-option>
					<el-option label="离队" value="1"></el-option>
				</el-select>

				<div style="float: left">
					<el-input
						@keyup.enter.native="getData(true)"
						class="left-child-input left-child-input-content"
						clearable
						suffix-icon="iconfont iconsousuo"
						placeholder="请输入关键字"
						style="width: 200px; float: left"
						v-model="input4"
					>
					</el-input>
					<el-button @click="getData(true)" slot="append" class="searchBtn" round>搜索</el-button>
				</div>
			</div>
		</div>

		<ThreeStaffList
			:listData="tableData"
			@change-check="tableSelectionChange"
			:contentText="contentText"
			v-loading="tableLoading"
			@go-Back="goBackButton"
			@go-detail="goDetai"
			@go-delete="goDelete"
			:buttonFlag="buttonFlag"
		>
		</ThreeStaffList>
		<el-pagination
			:key="elementui_page_component_key"
			:current-page.sync="currentPage"
			:page-size="12"
			:total="tableTotal"
			@current-change="handleCurrentChange"
			class="table-pagination"
			layout="total, prev, pager, next , jumper"
		>
		</el-pagination>
	</div>
</template>

<script>
let data = {
	name: '', //姓名
	sex: '', //性别
	birthday: '',
	height: '',
	weight: '',
	nation: '', //民族
	department_uuid: '', //运动队
	station_uuid: '', //岗位
	grade: '', //级别
	sport_uuid: '', //运动类
	sport_event_uuid: [], //运动项
	in_time: '', //入队时间
	native_place: '', //籍贯
	identity_type: '', //证件类型
	identity_number: '', //证件号
	coach_name: '', //教练
	sport_year: '', //年限
	permanent_address: '', //常驻地址
}

let dataNext = {
	mobile: '', //手机号
	email: '', //邮箱
	emergency_name1: '', //紧急联系人1名称
	emergency_relation1: '', //紧急联系人1关系
	emergency_contact1: '', //紧急联系人1手机号
	emergency_name2: '',
	emergency_relation2: '',
	emergency_contact2: '',
}
import threeStaffList from './../../components/staffList/threeStaffList.vue'
export default {
	data() {
		return {
			station_uuid: '765CFAE1-7EF8-E85F-CBAE-4702B3B2F177',
			contentText: [
				{
					name: '自我评价状态',
					field: 'subjective_status',
				},
				{
					name: '更新日期',
					field: 'subjective_status_date',
				},
			],
			buttonFlag: false,
			buttonJSon: [
				{
					html: '查看',
					goBack: 'look',
					Authority: '查看',
				},
				{
					html: '删除',
					goBack: 'del',
					Authority: '删除',
				},
			],
			currentPage: 1,
			tableTotal: 0,
			tableLoading: false,
			tableData: [],
			selectArr: {
				type: '',
				project: '',
				jobs: '',
				sex: '',
				inputType: '',
				grade: '',
				deparmentId: [],
			},
			sexOptions: {
				options: [
					{
						value: '1',
						label: '男',
					},
					{
						value: '2',
						label: '女',
					},
				],
				value: '',
			},
			departmentOptions: {
				options: [
					{
						value: '体育部',
						label: '体育部',
					},
					{
						value: '运动部',
						label: '运动部',
					},
					{
						value: '台球部',
						label: '台球部',
					},
				],
			},
			jobsOptions: {
				options: [
					{
						value: '运动员',
						label: '运动员',
					},
					{
						value: '会计',
						label: '会计',
					},
					{
						value: '财务',
						label: '财务',
					},
				],
			},
			levelOptions: {
				options: [
					{
						value: '1',
						label: '一级',
					},
					{
						value: '2',
						label: '二级',
					},
				],
			},
			between: {
				options: [
					{
						value: '1',
						label: '父子',
					},
					{
						value: '2',
						label: '母亲',
					},
					{
						value: '3',
						label: '兄弟',
					},
					{
						value: '4',
						label: '姐妹',
					},
					{
						value: '5配偶',
						label: '兄弟',
					},
					{
						value: '6',
						label: '儿女',
					},
				],
			},
			typeOptions: {
				options: [],
			},
			projectOptions: {
				options: [],
			},
			lationshipOptions: {
				options: [],
			},
			value: '',
			input4: '',
			addUserDialog: false,

			ruleForm: {
				...data,
			},
			ruleFormNext: {
				...dataNext,
			},
			rules: {
				name: this.$rules.name,
				id: { ...this.$rules.noEmpty, message: 'id不能为空' },
				sex: { ...this.$rules.noEmpty, message: '性别不能为空' },
				age: { ...this.$rules.noEmpty, message: '年龄不能为空' },
				department_uuid: {
					...this.$rules.noEmpty,
					message: '所属运动队不能为空',
				},
				mobile: this.$rules.phone,
				permanent_address: {
					...this.$rules.noEmpty,
					message: '常驻不能为空',
				},
				station_uuid: {
					...this.$rules.noEmpty,
					message: '岗位不能为空',
				},
				sport_uuid: {
					...this.$rules.noEmpty,
					message: '运动类不能为空',
				},
				sport_event_uuid: {
					...this.$rules.noEmpty,
					message: '运动项不能为空',
				},
				birthday: {
					...this.$rules.noEmpty,
					message: '出生日期不能为空',
				},
				height: { ...this.$rules.isNumber, message: '身高格式不正确' },
				weight: { ...this.$rules.isNumber, message: '体重格式不正确' },
				grade: { ...this.$rules.noEmpty, message: '民族不能为空' },
				level: { ...this.$rules.noEmpty, message: '级别不能为空' },
				in_time: {
					...this.$rules.noEmpty,
					message: '入队时间不能为空',
				},
				nation: { ...this.$rules.noEmpty, message: '民族不能为空' },
				native_place: {
					...this.$rules.noEmpty,
					message: '户籍不能为空',
				},
				email: this.$rules.email,
				emergency_name1: {
					...this.$rules.noEmpty,
					message: '紧急联系人不能为空',
				},
				emergency_relation1: {
					...this.$rules.noEmpty,
					message: '联系人关系不能为空',
				},
				emergency_contact1: this.$rules.phone,
				emergency_name2: {
					...this.$rules.noEmpty,
					message: '紧急联系人不能为空',
				},
				emergency_relation2: {
					...this.$rules.noEmpty,
					message: '联系人关系不能为空',
				},
				emergency_contact2: this.$rules.phone,
				coach_name: {
					...this.$rules.noEmpty,
					message: '教练名称不能为空',
				},
				sport_year: {
					...this.$rules.noEmpty,
					message: '运动年限不能为空',
				},
				identity_number: {
					...this.$rules.noEmpty,
					message: '证件号不能为空',
				},
			},
			addUserDialogStep2: false,
			imgData: null,
			tableDataSelectArr: [],
			imgFile: null,
			fullscreenLoading: false,
			sportType: false,
			elementui_page_component_key: 0,
		}
	},
	created() {
		if (this.$route.query.page) {
			this.$set(this, 'currentPage', Number(this.$route.query.page))
		}
		if (this.$route.query.uuid) {
			// this.getData();
		}
		this.getSelectData()
		// this.getTree();
	},
	components: {
		ThreeStaffList: threeStaffList,
	},
	watch: {
		$route(to, from) {
			if (this.$route.query.uuid && this.sportType) {
				this.currentPage = 1
				// this.getData();
			}
		},
	},
	mounted() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.selectArr = {
				type: obj.type,
				project: obj.project,
				jobs: obj.jobs,
				sex: obj.sex,
				inputType: obj.inputType,
				grade: obj.grade,
				deparmentId: obj.deparmentId,
				isOut: obj.isOut,
			}
			this.currentPage = obj.currentPage
			this.elementui_page_component_key++
			this.input4 = obj.name
		}
	},
	methods: {
		handleClick() {
			if (this.station_uuid === '765CFAE1-7EF8-E85F-CBAE-4702B3B2F177') {
				this.contentText = [
					{
						name: '运动队',
						field: 'department_name',
					},
					{
						name: '项目',
						field: 'sport_name',
					},
					{
						name: '教练',
						field: 'coach_name',
					},
				]
			} else {
				this.contentText = [
					{
						name: '运动队',
						field: 'department_name',
					},
				]
			}

			this.tableDataSelectArr = []

			this.currentPage = 1
			this.getData()
		},

		goBackButton(val, res) {
			if (val.goBack === 'look') {
				this.$router.push({ path: `/management/eRecord/architecMore/${res.uuid}?department_uuid=${this.$route.query.uuid}` })
			} else if (val.goBack === 'del') {
				this.delTableData(res)
			}
		},

		goDelete(res) {
			this.delTableData(res)
		},

		goDetai(res) {
			if (this.$store.getters.permissionsStr('查看')) {
				return this.$message({
					message: '暂无权限',
					type: 'warning',
				})
			}
			sessionStorage.setItem('arch_search', JSON.stringify({ ...this.selectArr, currentPage: this.currentPage, name: this.input4 }))
			this.$router.push({
				path: `/conditionModule/subjectiveStateDetails?page=${this.currentPage}&uuid=${res.uuid}&department_uuid=${this.$route.query.uuid}&edit=${
					this.$store.getters.permissionsStr('编辑') ? 1 : 0
				}`,
			})
		},

		// getTree () {
		//   this.$axios.get("/p/staff/tree").then((res) => {
		//     if (res.data.code === 0) {
		//       this.defaultChecked = this.level2IsShow && this.$route.query.uuid ? [this.$route.query.uuid] : [res.data.data.department[0].uuid];
		//       this.$store.state.lastTreeMenu = res.data.data.department[0];
		//       this.$store.state.treeMenu = res.data.data.department;
		//       this.$store.state.treeTransferData = res.data.data.department;
		//     }
		//   })
		// },
		addUserDialogClick() {
			this.addUserDialog = true
			setTimeout(() => {
				this.$dialogHeight()
				this.$refs.ruleForm.resetFields()
			}, 0)
		},
		//导出
		expor() {
			let arr = ''
			if (this.selectArr.deparmentId.length > 0) {
				this.selectArr.deparmentId.forEach((item, index) => {
					if (index == 0) {
						arr = item
					} else {
						arr += ',' + item
					}
				})
			}

			let station_uuid = this.station_uuid === 'null' ? '' : this.station_uuid

			window.open(
				this.$store.state.url +
					'/p/staff/export/?department_uuid_str=' +
					arr +
					'&sex=' +
					this.selectArr.sex +
					'&sport_uuid=' +
					this.selectArr.type +
					'&is_out=' +
					this.selectArr.isOut +
					'&name=' +
					this.input4 +
					'&station_uuid=' +
					station_uuid
			)
		},
		delMoreData() {
			this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				let arr = []
				this.tableDataSelectArr.forEach((i, k) => {
					arr.push(i.uuid)
				})
				this.$axios
					.post(
						'/p/staff/delete',
						this.$qs({
							uuid: arr.join(','),
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.getData()
							this.$utils.message()
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			})
		},
		clearSelect() {
			this.selectArr.project = ''
			this.projectOptions.options = []
			this.getData()
		},
		selectTypeArr(val, type) {
			this.typeOptions.options.forEach((i, k) => {
				if (i.uuid == val) {
					if (!this.addUserDialog) {
						this.selectArr.project = ''
						if (!type) {
							this.getData()
						}
					}
					this.projectOptions.options = i.children
				}
			})
			// if(this.selectArr.type ){
			// 	this.projectOptions.options = [];
			// }
			this.ruleForm.sport_event_uuid = []
			this.selectArr.project = ''
		},
		getData(keyEnter) {
			let arr = ''
			if (this.selectArr.deparmentId.length > 0) {
				this.selectArr.deparmentId.forEach((item, index) => {
					if (index == 0) {
						arr = item
					} else {
						arr += ',' + item
					}
				})
			}
			// this.sportType = true;
			// if (
			//   this.input4 != "" &&
			//   keyEnter
			// ) {
			//   return this.$message({
			//     message: "请选择关键字类型",
			//     type: "error"
			//   });
			// }
			this.tableLoading = true
			let data = {
				department_uuid_str: arr,
				page: this.currentPage,
				sex: this.selectArr.sex,
				sport_uuid: this.selectArr.type,
				name: this.input4,
				is_out: this.selectArr.isOut,
				page_size: 12,
				from: 'statusRecord',
			}

			// if (this.selectArr.inputType == "1") {
			//   data.code = this.input4;
			// } else if (this.selectArr.inputType == "2") {
			//   data.name = this.input4;
			// } else if (this.selectArr.inputType == "3") {
			//   data.mobile = this.input4;
			// } else if (this.selectArr.inputType == "4") {
			//   data.grade = this.input4;
			// }

			// data.station_uuid = this.station_uuid === 'null' ? '' : this.station_uuid

			for (let i in data) {
				if (data[i] == '') {
					delete data[i]
				}
			}

			this.$axios
				.post('/status/lists', this.$qs(data))
				.then(res => {
					if (res.data.code == 0) {
						res.data.data.rows.forEach(item => {
							item['checked'] = false
						})
						this.tableData = res.data.data.rows
						this.tableTotal = res.data.data.total
					} else {
						this.$message({
							message: res.message,
							type: 'warning',
						})
					}
				})
				.then(() => {
					this.tableLoading = false
				})
				.catch(error => {
					console.log(error)
				})
		},
		getSelectData() {
			this.$axios
				.all([
					this.$axios.post('/p/staff/sport'),
					this.$axios.post('/p/staff/tree'),
					// this.$axios.post("/p/station/show")
				])
				.then(
					this.$axios.spread((res1, res2, res3) => {
						this.departmentOptions.options = res2.data.data.department
						this.typeOptions.options = res1.data.data
						// this.jobsOptions.options = res3.data.data;
						// this.selectArr.type = this.typeOptions.options[0].uuid;
						// this.selectTypeArr(this.typeOptions.options[0].uuid,true);
						// this.selectArr.project = this.projectOptions.options[0].uuid;
						this.getData()
					})
				)
		},
		changeFile(e) {
			function getObjectURL(file) {
				var url = null
				if (window.createObjectURL != undefined) {
					// basic
					url = window.createObjectURL(file)
				} else if (window.URL != undefined) {
					// mozilla(firefox)
					url = window.URL.createObjectURL(file)
				} else if (window.webkitURL != undefined) {
					// webkit or chrome
					url = window.webkitURL.createObjectURL(file)
				}
				return url
			}

			let imgData = e.target.files[0]
			this.imgFile = imgData
			this.imgData = getObjectURL(imgData)
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getData()
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					alert('submit!')
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		/*表格单行删除*/
		delTableData(row) {
			this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
			}).then(() => {
				this.$axios
					.post(
						'/p/staff/delete',
						this.$qs({
							uuid: row.uuid,
						})
					)
					.then(res => {
						if (res.data.code == 0) {
							this.$utils.message()
							this.getData()
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			})
		},
		/*弹出框，下一步*/
		dialogNext() {
			let submit = () => {
				this.addUserDialog = false
				this.addUserDialogStep2 = true
			}
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					submit()
				} else {
					return false
				}
			})
		},
		save() {
			let submit = () => {
				let department_uuid = []
				this.ruleForm.department_uuid.forEach((i, k) => {
					department_uuid.push(i[i.length - 1])
				})
				let data = {
					...this.ruleForm,
					...this.ruleFormNext,
					department_uuid: department_uuid.join(','),
					sport_event_uuid: this.ruleForm.sport_event_uuid.join(','),
				}
				let formData = new FormData()
				formData.append('head_img', this.imgFile)
				for (let i in data) {
					formData.append(i, data[i])
				}
				this.$axios
					.post('/p/staff/add', formData)
					.then(res => {
						if (res.data.code == 0) {
							this.$refs.ruleFormNext.resetFields()
							this.getData()
							this.addUserDialogStep2 = false
							this.$utils.message()
						} else {
							this.$utils.message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
			this.$refs['ruleFormNext'].validate(valid => {
				if (valid) {
					submit()
				} else {
					return false
				}
			})
		},
		tableSelectionChange(val) {
			this.tableDataSelectArr = val
		},

		// 格式化日期格式为 xxxx-xx-xx
		getFullDate(targetDate) {
			var D, y, m, d
			if (targetDate) {
				D = new Date(targetDate)
				y = D.getFullYear()
				m = D.getMonth() + 1
				d = D.getDate()
			} else {
				y = fullYear
				m = month
				d = date
			}
			m = m > 9 ? m : '0' + m
			d = d > 9 ? d : '0' + d
			return y + '-' + m + '-' + d
		},
	},
}
</script>

<style lang="scss">
.el-tabs__item {
	font-size: 18px;
}
</style>

<style lang="scss" scoped>
.body-function {
	padding-top: 15px;
}
.table-pagination {
	text-align: right;
	margin-top: 10px;
	/deep/ span {
		float: left;
	}
}
.child-top {
	flex-direction: column;
	margin-bottom: 10px;
	padding-bottom: 20px;
	border-radius: 5px;
	.input-from {
		width: 100%;
		margin-bottom: 0;

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 10px;

			.el-input__inner {
				border-radius: 0;
				border-right: transparent;
			}

			.el-input__inner:focus {
				border-right: 1px solid #409eff;
			}
		}

		.el-select,
		.el-input {
			margin-top: 10px;
		}

		.el-select:nth-child(4) {
			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(5) {
			.el-input__inner {
				border-right: 0;
			}

			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(6) {
			width: 120px;
			margin-left: 10px;

			.el-input__inner {
				border-radius: 0px 4px 4px 0;
			}
		}

		.el-select:nth-child(1) {
			.el-input__inner {
				border-radius: 4px 0 0 4px;
			}
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;

			.el-input__inner {
				border-radius: 0 4px 4px 0;
			}
		}
	}
	.operation-button {
	}
}

.el-input-group__append {
	.el-button.el-button--default {
		color: #fff;
		background-color: #409eff;
		border-color: #409eff;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.staffList {
	background: #fff;
}

.el-pagination {
	background: #fff;
	padding-bottom: 10px;
	margin-top: 0;
	padding-top: 20px;
}
</style>
